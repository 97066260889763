<template>
    <div class="home">
      <el-menu :default-active="formInline.order_type+''" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">失败</el-menu-item>
        <el-menu-item index="2">律师</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.search_data" style="width:400px" placeholder="请输入姓名/手机/航班号/航空公司"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
            <el-button  type="info"  style="position: absolute;right: 45px;" @click="exportlist">导出</el-button> 
      </el-form> 
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="id"
          width="70"
          label="编号">
        </el-table-column>
        <el-table-column
          prop="fdate"
          label="航班日期">
        </el-table-column>
        <el-table-column
          prop="fnum"
          width="80"
          label="航班号">
        </el-table-column>
        <el-table-column
          prop="fcom"
          label="航空公司">
        </el-table-column>
        <el-table-column
          prop="dcity"
          width="70"
          label="起飞地">
        </el-table-column>
        <el-table-column
          prop="acity"
          width="70"
          label="到达地">
        </el-table-column>
        <el-table-column
          prop="cptype_text" 
          label="投诉类型">
        </el-table-column>
        <el-table-column
          prop="name"
          width="80"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机">
        </el-table-column>
        <el-table-column
          prop="reason_text"
          width="320"
          label="具体原因">
        </el-table-column>
        <el-table-column
          prop="desc"
          label="备注">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间">
          <template  slot-scope="scope">
            {{scope.row.create_time | formatDate2}}
         </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          :page-sizes="[10, 20, 30, 40 ,50]"
          layout="sizes,total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'
import * as XLSX from 'xlsx';

export default {
  name: 'Home',
  data(){
    return {
      dateValue: '',
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      payChannel:[],//支付渠道
      formInline: {
         search_data:'',//检索内容
         order_type:0,//类型 0全部 1失败2律师
      },
      status:'',//支付渠道
      // 选中数组
      ghs: [],
      nums:[],
     //选中的记录数量
     initdata:{
         search_data:'',//检索内容
         order_type:0,//类型 0全部 1失败2律师
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate2(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
      exportlist(){
        const jsonData = [
        ["编号", "航班日期", "航班号", "航空公司", "起飞地", "到达地", "投诉类型", "姓名", "手机", "具体原因", "备注", "创建时间"]
        ];
      let tData = this.tableData;
       for(let i in tData){
        let arr = [];
        arr.push(tData[i]['id']);
        arr.push(tData[i]['fdate']);
        arr.push(tData[i]['fnum']);
        arr.push(tData[i]['fcom']);
        arr.push(tData[i]['dcity']);
        arr.push(tData[i]['acity']);
        arr.push(tData[i]['cptype_text']);
        arr.push(tData[i]['name']);
        arr.push(tData[i]['phone']);
        arr.push(tData[i]['reason_text']);
        arr.push(tData[i]['desc']);
        let create_time = ctime(tData[i]['create_time']);
        arr.push(create_time);
        jsonData.push(arr);
       }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(jsonData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      let date = new Date();
      let tt = date.getFullYear() +'-'+ ('0' + (date.getMonth() + 1)).slice(-2) +'-'+ ('0' + date.getDate()).slice(-2);
      XLSX.writeFile(wb, `失败订单 ${tt}.xlsx`);
            function ctime(timestamp){
              const date = new Date(timestamp*1000);
              const year = date.getFullYear();
              const month = ('0' + (date.getMonth() + 1)).slice(-2);
              const day = ('0' + date.getDate()).slice(-2);
              const hour = ('0' + date.getHours()).slice(-2);
              const minute = ('0' + date.getMinutes()).slice(-2);
              const second = ('0' + date.getSeconds()).slice(-2);
              return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            }
      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        this.formInline.limit = _this.per_page;
        axios.get(config.fail_order,{params:_this.formInline})
        .then(function (response) {
              _this.tableData = response.data.data;
              _this.setpage(response.data)
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleSizeChange(val){
        this.per_page = val*1;
        this.onSubmit();
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.fail_order,{params:this.initdata})
        .then(function (response) {
              _this.tableData = response.data.data
              _this.setpage(response.data)
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.pay_channel)
        .then(function (response) {
            if(response.data.code == 200){
              _this.payChannel = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
</style>